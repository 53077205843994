import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../../Pages/Contact/contact.css";
import formPattern from "../../../assets/images/form-pattern.png";
import Map from "../../../assets/images/map.png";
import axios from "axios";

const ContactUs = () => {
  const [t] = useTranslation("global");
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const phoneNumberRef = useRef(null);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();

      if (!state?.phone_number || state?.phone_number === "") {
        toast.error("Phone Number Is Required");
        setLoading(false);
        return;
      }
      console.log(
        "process.env.REACT_APP_API_URL----->",
        process.env.REACT_APP_API_URL
      );
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/enquiry`,
        state
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setState({
          name: "",
          phone_number: "",
          email: "",
          address: "",
          postal_code: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      toast.error(error?.message ?? "Something Went Wrong");
    }
    setLoading(false);
  };

  // Add an event listener to handle scroll events
  const handleScroll = () => {
    // Show the "Back to Top" button when the user scrolls beyond a certain point
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  // Add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container">
      <div className="form reservation-form bg-black-10">
        <form action="" className="form-left" onSubmit={handleSubmit}>
          <h2 className="headline-1 text-center">{t("dish.onl")}</h2>

          <p className="form-text text-center">
            {t("navbar.booking")}{" "}
            <a href="tel:+2252721212151" className="link">
              +2252721212151{" "}
            </a>
            {t("dish.o-frm")}
          </p>

          <div className="input-wrapper">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              autocomplete="off"
              className="input-field"
              onChange={handleChange}
              value={state?.name}
            />
            <PhoneInput
              ref={phoneNumberRef}
              inputProps={{
                name: "phone_number",
                required: true,
              }}
              country={"ci"}
              placeholder="Phone Number"
              enableLongNumbers="false"
              className="input-field-country"
              onChange={(value) => {
                // eslint-disable-next-line no-useless-computed-key
                setState((prev) => ({ ...prev, ["phone_number"]: value }));
              }}
              value={state?.phone_number}
            />
            <input
              type="email"
              name="email"
              required
              placeholder="Your Email"
              autocomplete="off"
              className="input-field"
              onChange={handleChange}
              value={state?.email}
            />
            <input
              type="text"
              name="address"
              placeholder="Your Country"
              autoComplete="off"
              className="input-field"
              onChange={handleChange}
              value={state?.address}
            />
            <input
              type="text"
              name="postal_code"
              placeholder="Postal Code"
              autoComplete="off"
              className="input-field"
              onChange={handleChange}
              value={state?.postal_code}
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              autoComplete="off"
              className="input-field"
              onChange={handleChange}
              value={state?.subject}
            />
          </div>

          <textarea
            name="message"
            placeholder="Message"
            autocomplete="off"
            className="input-field"
            onChange={handleChange}
            value={state?.message}
          />

          <button type="submit" className="btn btn-secondary">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <CircularProgress
                  style={{ height: "20px", width: "20px" }}
                  color="inherit"
                />
                Loading...
              </div>
            ) : (
              <>
                <span className="text text-1">{t("hero.contactus")}</span>
                <span className="text text-2" aria-hidden="true">
                  {t("hero.contactus")}
                </span>
              </>
            )}
          </button>
        </form>
        <div
          className="form-right text-center"
          style={{ backgroundImage: `url(${formPattern})` }}
        >
          <h2 className="headline-1 text-center">{t("hero.contactus")}</h2>

          <p className="contact-label">{t("navbar.booking")}</p>

          <a
            href="tel:+2252721212151"
            className="body-1 contact-number hover-underline"
          >
            <span>+2252721212151</span>
          </a>

          <div className="separator"></div>

          <p className="contact-label">{t("dish.lc")}</p>

          <address className="body-4">{t("navbar.location")}</address>

          <a
            href="https://www.google.com/maps/place/H%C3%B4tel+Le+Marly/@6.3500581,-2.7742593,5.63z/data=!4m18!1m9!3m8!1s0xfc1eec182a3b403:0xea3cee2fa41e350f!2sLe+Marly!5m2!4m1!1i2!8m2!3d5.2720915!4d-3.9699617!3m7!1s0xfc1eec184a6d3a1:0x6c06005e6fdc4459!5m2!4m1!1i2!8m2!3d5.2721032!4d-3.9696569"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Map} className="map-image" alt="map-img" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
