import React, { useState } from "react";
import "./Hero.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    console.log("here is---->");
    try {
      e.preventDefault();

      window.location.href =
        "https://live.ipms247.com/booking/book-rooms-hotellemarly";
      return;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reservation`,
        state
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setState({
          checkInDate: "",
          checkOutDate: "",
          adults: "",
          children: "",
          numberOfRooms: "",
          email: "",
        });
      }
    } catch (error) {
      toast.error(error?.message ?? "Something Went Wrong");
    }
    setLoading(false);
  };

  return (
    <>
      {/* <section className="book book-hidden">
        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="input Form">
              <div className="box image-box">
                <i class="fas fa-calendar-check"></i>
                <label>{t("book.checkin")}</label>
                <input
                  className="input-style"
                  type="date"
                  placeholder="Check-in-Date"
                  name="checkInDate"
                  onChange={handleChange}
                  value={state?.checkInDate}
                  // required
                />
              </div>

              <div className="box image-box">
                <i class="fas fa-calendar-times"></i>
                <label>{t("book.checkout")}</label>
                <input
                  className="input-style"
                  type="date"
                  placeholder="Check-out-Date"
                  name="checkOutDate"
                  onChange={handleChange}
                  value={state?.checkOutDate}
                  // required
                />
              </div>

              <div className="box image-box">
                <div className="flexTitle">
                  <i class="fas fa-user"></i>
                  <label>{t("book.adults")}</label> <br />
                </div>

                <input
                  className="input-style"
                  type="number"
                  placeholder="0"
                  name="adults"
                  onChange={handleChange}
                  value={state?.adults}
                  // required
                />
              </div>

              <div className="box image-box">
                <div className="flexTitle">
                  <i class="fas fa-child"></i>
                  <label>{t("book.child")}</label> <br />
                </div>
                <input
                  className="input-style"
                  type="number"
                  placeholder="0"
                  name="children"
                  onChange={handleChange}
                  value={state?.children}
                />
              </div>

              <div className="box image-box">
                <div className="flexTitle">
                  <i class="fas fa-house"></i>
                  <label>{t("book.rooms")}</label> <br />
                </div>
                <input
                  className="input-style"
                  type="number"
                  placeholder="0"
                  name="numberOfRooms"
                  onChange={handleChange}
                  value={state?.numberOfRooms}
                  // required
                />
              </div>

              <div className="box image-box">
                <div className="flexTitle">
                  <i class="fa-solid fa-envelope"></i>
                  <label>E-mail:</label> <br />
                </div>
                <input
                  className="input-style"
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={state?.email}
                  // required
                />
              </div>
            </div>
            <div className="search">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <CircularProgress
                    style={{ height: "20px", width: "20px" }}
                    color="inherit"
                  />
                  Loading...
                </div>
              ) : (
                <input type="submit" value={t("hero.booknow")} />
              )}
            </div>
          </div>
        </form>
      </section> */}
    </>
  );
};

export default Hero;
