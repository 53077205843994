import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import "./App.css";
import About from "./Pages/About/Index";
import Rooms from "./Pages/Rooms/Index";
import Restaurant from "./Pages/Restaurant/index";
import Gallery from "./Pages/Gallery/Index";
import Services from "./Pages/Services/Index";
import Contact from "./Pages/Contact/Index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WhatsappButton from "./components/WhatsappButton";

const App = () => {
  useEffect(() => {
    const preloader = document.querySelector("[data-preaload]");

    window.addEventListener("load", () => {
      setTimeout(() => {
        preloader?.classList?.add("loaded");
        document.body?.classList?.add("loaded");
      }, 1000); // Set the preloader load time to 1/3 (1000ms)
    });

    const addEventOnElements = (elements, eventType, callback) => {
      for (let i = 0; i < elements.length; i++) {
        elements[i]?.addEventListener(eventType, callback);
      }
    };

    const header = document.querySelector("[data-header]");
    const backTopBtn = document.querySelector("[data-back-top-btn]");

    let lastScrollPos = 0;

    const hideHeader = () => {
      const isScrollBottom = lastScrollPos < window.scrollY;
      if (isScrollBottom) {
        header?.classList?.add("hide");
      } else {
        header?.classList?.remove("hide");
      }

      lastScrollPos = window.scrollY;
    };

    window.addEventListener("scroll", () => {
      if (window.scrollY >= 50) {
        header?.classList?.add("active");
        backTopBtn?.classList?.add("active");
        hideHeader();
      } else {
        header?.classList?.remove("active");
        backTopBtn?.classList?.remove("active");
      }
    });

    const heroSlider = document.querySelector("[data-hero-slider]");
    const heroSliderItems = document.querySelectorAll(
      "[data-hero-slider-item]"
    );
    const heroSliderPrevBtn = document.querySelector("[data-prev-btn]");
    const heroSliderNextBtn = document.querySelector("[data-next-btn]");

    let currentSlidePos = 0;
    let lastActiveSliderItem = heroSliderItems[0];

    const updateSliderPos = () => {
      lastActiveSliderItem?.classList?.remove("active");
      heroSliderItems[currentSlidePos]?.classList?.add("active");
      lastActiveSliderItem = heroSliderItems[currentSlidePos];
    };

    const slideNext = () => {
      if (currentSlidePos >= heroSliderItems.length - 1) {
        currentSlidePos = 0;
      } else {
        currentSlidePos++;
      }

      updateSliderPos();
    };

    // heroSliderNextBtn?.addEventListener("click", slideNext);

    const slidePrev = () => {
      if (currentSlidePos <= 0) {
        currentSlidePos = heroSliderItems.length - 1;
      } else {
        currentSlidePos--;
      }

      updateSliderPos();
    };

    heroSliderPrevBtn?.addEventListener("click", slidePrev);

    let autoSlideInterval;

    const autoSlide = () => {
      autoSlideInterval = setInterval(() => {
        slideNext();
      }, 7000);
    };

    addEventOnElements(
      [heroSliderNextBtn, heroSliderPrevBtn],
      "mouseover",
      () => {
        clearInterval(autoSlideInterval);
      }
    );

    addEventOnElements(
      [heroSliderNextBtn, heroSliderPrevBtn],
      "mouseout",
      autoSlide
    );

    window.addEventListener("load", autoSlide);

    const parallaxItems = document.querySelectorAll("[data-parallax-item]");

    let x, y;

    window.addEventListener("mousemove", (event) => {
      x = (event.clientX / window.innerWidth) * 10 - 5;
      y = (event.clientY / window.innerHeight) * 10 - 5;
      x = x - x * 2;
      y = y - y * 2;
      for (let i = 0; i < parallaxItems.length; i++) {
        x = x * Number(parallaxItems[i].dataset.parallaxSpeed);
        y = y * Number(parallaxItems[i].dataset.parallaxSpeed);
        parallaxItems[i].style.transform = `translate3d(${x}px, ${y}px, 0px)`;
      }
    });
  }, []);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/restaurant" element={<Restaurant />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <WhatsappButton />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default App;
